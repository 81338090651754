const menus = [
    // {
    //     id: 1,
    //     name: 'Home',
    //     links: '/'
    // },

    // {
    //     id: 2,
    //     name: 'Mint',
    //     links: '#'
    // },



    // {
    //     id: 5,
    //     name: 'MY AVATARS',
    //     links: '/nfts'
    // },

    // {
    //     id: 4,
    //     name: 'Networks',
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Road Map 01',
    //             links: '/road-map-v1'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Road Map 02',
    //             links: '/road-map-v2'
    //         },
    //     ]
    // },
    
    
]

export default menus;