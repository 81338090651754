const dataAbout = [
    {
        id: 1,
        title: 'WHO IS YOUR GOD?',
        desc: 'CRYPTO BOMBER BET starts off as a classic PFP NFT project randomly minted in candy-machine style. Devoted to cyberpunk art and culture, NGS brings value to your metaverse project as timeless Neon Pop Art, opening new doors and possibilities for builders, investors, brands and fans.',
        class: 'active'
    },
    {
        id: 2,
        title: 'UNIQUE 1/1',
        desc: 'The NGS algorithm has randomly selected 11,111 from a total of 152,688 UNIQUE 1/1 NEON GODS generated from over 50 different character traits, accessories and colors which will be dropped in different phases (TBA) - PHASE 0 starting with PREMINT.',
    },
    {
        id: 3,
        title: 'USER RIGHTS INCLUDED ',
        desc: 'NGS believes in the exponential branding power of BAYC and many other NFT projects by granting NGS holders full ownership - IP rights - over visual art or any other media in the metadata. We are not a P2E. We are a Neon Art Society for all Metaverses.',
    },
]

export default dataAbout;