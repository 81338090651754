import img1 from '../images/common/0001.png'

const dataProject = [
	{
		id: 1,
		title: 'NEON GODS  #01',
		img: img1,
	}
]

export default dataProject;