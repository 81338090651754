import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';
import Player from '../../../components/player';
import { uriIMAGE } from '../../../utils/constants';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Disco from '../../../assets/images/common/0001.png';



ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {

    const { item } = props;

    return (
        <div className="img-box-internal">
            {/* <Link to="/nft-item-details">
                <img src={`https://blvck.mypinata.cloud/ipfs/${uriIMAGE}/${item.toString()}.png`} alt="crybox" className="img-disco" />
            </Link> */}



            <Swiper
                spaceBetween={30}

                modules={[Autoplay]}
                slidesPerView={1}
                pagination={{ clickable: true }}
                loop
                autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                speed={10000}
            >
                <SwiperSlide>
                    <img src={`https://blvck.mypinata.cloud/ipfs/${uriIMAGE}/${item.toString()}.png`} alt="crybox" className="img-disco" />
                </SwiperSlide>

                <SwiperSlide>
                    <div className="img-disco-bg">
                        <img src={Disco} alt="crybox" className="img-disco" />
                    </div>

                    {/* <div style={{}}>
                        <Player />
                    </div> */}

                    {/* <div className="name-text-disco">
                        VINYL ART 
                    </div> */}
                </SwiperSlide>
            </Swiper>

            <div className="title-container-item">

                <div className="name-text-rarity">
                    NEON GODS #{item.toString()}
                </div>

                <div className="name-text">
                    LEGEND
                </div>

            </div>


        </div>
    );
}

export default ProjectItem;