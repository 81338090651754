import React from 'react';
import '../scss/components/section.scss';
import '../scss/components/box.scss';
import About from '../features/about/home-v3';
import dataAbout from '../assets/fake-data/data-about';
import Mint from '../features/home';
import dataTeam from '../assets/fake-data/data-team';


function HomeOne(props) {

    return (

        <div className='home-1'>
            {/* <Slider data={dataSlider} /> */}

            <Mint data={dataTeam} />

            <About data={dataAbout} />
        </div>


    );
}

export default HomeOne;


/*




.bg-noise {
    background-image: url(/images/home/bg-noise.png);
    background-repeat: repeat;
    -webkit-font-smoothing: antialiased;
    background-color: #32333e
}

*/