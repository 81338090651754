import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'
import AboutItem from '../about-item';
import Button from '../../../components/button';

About.propTypes = {
    data: PropTypes.array,
};

function About(props) {

    const { data } = props;

    const [dataBlock] = useState(
        {
            subtitle: 'FAQS',
            title: 'COMO FUNCIONA?',
            desc: 'Nessa roleta você nunca perde, ao escolher um número nosso contrato rolará aleatoriamente um número de 1 a 2, se for o mesmo número que você escolheu você receberá o reembolso do valor pago, mais o prêmio e + 1 unidade de NFT (CRYPTO BOMBER NFT), se você rolar o número errado, o contrato retém o valor cobrado e você receberá a NFT'
        }
    )

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about mobie-40 mb-60" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p className='pd10 center'>{dataBlock.desc}</p>
                        </div>

                        {/* <div className="tf-title">
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                        <p className='pd10 center'>{dataBlock.desc}</p> */}

                    </div>

                    {/* {
                            data.map(item => (
                                <div key={item.id} className="col-xl-4 col-lg-4 col-md-12" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                    <AboutItem item={item} />
                                </div>
                            ))
                        } */}

                    {/* <div className="col-md-12">
                        <div className="btn-about center" data-aos="fade-up" data-aos-duration="800">
                            <Button title='get Nfts' path='#' />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default About;