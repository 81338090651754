
import { React, useEffect } from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import {
	ScrollingProvider,
	Section,
} from 'react-scroll-section';
import { Web3Provider } from "@ethersproject/providers";
import Header from './components/header';
import Footer from './components/footer';
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Page404 from './pages/404';

function App() {

	useEffect(() => {
		AOS.init({
			duration: 2000
		});
	}, []);

	return (
		<ScrollingProvider>

			<Web3ReactProvider
				getLibrary={(provider) => new Web3Provider(provider)}
				libraryName={'ethers.js' | 'web3.js' | null}
			>

				<Header />
				<Routes>

					{
						routes.map((data, idx) => (
							<Route key={idx} path={data.path} element={data.component} exact />
						))
					}

					<Route path='*' element={<Page404 />} />
				</Routes>
				<Footer />
			</Web3ReactProvider>
		</ScrollingProvider>

	);
}

export default App;
