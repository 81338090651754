export const REACT_APP_RPC_URL = process.env.REACT_APP_RPC_URL || "";
export const REACT_APP_NETWORK_ID = process.env.REACT_APP_NETWORK_ID || 1;

export const addressNFT = "0xdE362da0eC4b9e4A765872Af57D4ABeEF816013E";

export const uriIMAGE = "QmWdBV8sCZ7k7yFhUWnLPqUSkGJKuFaw5Qqs2Y2KJZohgt";

export const uriMETADATA = "QmTd6wo7DNEBKBgPVx9P6kx23BXTL95gcE2fgty1L5oqWN";

export const symbolNetwork = 'ETH';

export const nameNetwork = 'Ethereum';

export const idNetwork = '137';





