import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {
    Section
} from 'react-scroll-section';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


import Cards from './cards';

Home.propTypes = {
    data: PropTypes.array,
};

function Home(props) {

    return (
        <Section id="mint">
            <section className="tf-section tf-team">
                <div className="container container-flex">

                    <div className="" data-aos="fade-up" data-aos-duration="800">
                        <p className="h8 sub-title"></p>
                        <h4 className="title" style={{ marginTop: 30, marginBottom: 1 }}>PLAY</h4>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800">
                        <Cards />
                    </div>
                </div>
            </section>
        </Section>

    );
}

export default Home;