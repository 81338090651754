import img1 from '../images/common/0001.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Esther Howard',
        position: 'Co-founder & COO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 2,
        img: img1,
        name: 'Devon Lane',
        position: 'Blockchain Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 3,
        img: img1,
        name: 'Ralph Edwards',
        position: 'Backend Engineer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 4,
        img: img1,
        name: 'Esther Howard',
        position: 'Co-founder & COO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 5,
        img: img1,
        name: 'Devon Lane',
        position: 'Blockchain Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 6,
        img: img1,
        name: 'Ralph Edwards',
        position: 'Backend Engineer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 7,
        img: img1,
        name: 'Esther Howard',
        position: 'Co-founder & COO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
    {
        id: 8,
        img: img1,
        name: 'Devon Lane',
        position: 'Blockchain Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
            {
                id: 2,
                icon: 'fab fa-facebook-f',
                link: '#'
            },
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            {
                id: 4,
                icon: 'fab fa-reddit',
                link: '#'
            },
        ]
    },
]

export default dataTeam;