import React, { useEffect, useState } from 'react';
import { Contract } from "@ethersproject/contracts";
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

import axios from 'axios';


// import { Link } from 'react-router-dom';
import img1 from '../../../assets/images/common/0001.png'
import img2 from '../../../assets/images/common/0002.png'
import loadingGif from '../../../assets/images/common/common.gif'
import imgFlip from '../../../assets/images/common/bg.jpg'




import './styles.scss';
import { addressNFT } from '../../../utils/constants';
import abiNFT from '../../../utils/abi/contractNFT.json';


function Card(props) {
    const { active, account, activate, chainId, library } = useWeb3React();

    const [amountMint, setAmountMint] = useState(1);
    const [price, setPrice] = useState(0);

    const [roulling, setRoulling] = useState(false);

    const [loading, setLoading] = useState(false);

    const [level, setLevel] = useState(1);


    const [side_1, setSide_1] = useState(true);
    const [side_2, setSide_2] = useState(false);


    const [statusDone, setStatusDone] = useState(false);
    const [statusWarn, setStatusWarn] = useState(false);
    const [statusError, setStatusError] = useState(false);

    const [statusErrorRPC, setStatusErrorRPC] = useState(false);


    useEffect(() => {
        async function init() {
            // let { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=moonbeam&vs_currencies=usd');

            // console.log(typeof data?.moonbeam.usd)

            // setPrice((data?.moonbeam.usd * 7.5).toFixed(2));

            if (!active) {
                activate(new InjectedConnector({ supportedNetworks: [137] }));
            }
            // if (active && account) {
            //     localStorage.setItem('account', account);
            // }

        }
        init();
    }, []);

    const contractNFT = new Contract(addressNFT, abiNFT, library?.getSigner());

    function playAgain() {
        setStatusDone(false);
        setStatusWarn(false);
        setStatusError(false);
        setRoulling(false);
        setLoading(false);
        setStatusErrorRPC(false);
    }

    function select1() {
        setSide_1(true);
        setSide_2(false);
    }

    function select2() {
        setSide_2(true);
        setSide_1(false);
    }

    async function minter(level) {
        setLevel(level)
        setStatusDone(false);
        setStatusError(false);

        setStatusWarn(false);
        setStatusErrorRPC(false);

        setLoading(true);
        setRoulling(true);


        if (!active) {
            activate(new InjectedConnector({ supportedNetworks: [137] }));
        }

        // let cost1 = await contractNFT.mintPrice();
        // let cost2 = await contractNFT.mintPriceMedium();
        // let cost3 = await contractNFT.mintPriceHigh();

        let cost1 = await contractNFT.mintPrice();
        let cost2 = await contractNFT.mintPriceMedium();
        let cost3 = await contractNFT.mintPriceHigh();

        let costFinal1 = parseInt(cost1.toString());
        let costFinal2 = parseInt(cost2.toString());
        let costFinal3 = parseInt(cost3.toString());


        let ticket = side_1 ? '0' : '1';

        try {

            let res;

            if (level === 1) {
                res = await contractNFT.mintLow(ticket, {
                    // The address to execute the call as
                    from: account,

                    // The maximum units of gas for the transaction to use
                    // gasLimit: 310000,

                    gasLimit: 1400000,
               
                    value: costFinal1.toString()
                });

            }


            if (level === 2) {
                res = await contractNFT.mintMedium(ticket, {
                    // The address to execute the call as
                    from: account,

                    // The maximum units of gas for the transaction to use
                    // gasLimit: 310000,

                    // gasLimit: 93000,
                    gasLimit: 1400000,

                    value: costFinal2.toString()
                });

            }

            if (level === 3) {
                res = await contractNFT.mintHigh(ticket, {
                    // The address to execute the call as
                    from: account,

                    // The maximum units of gas for the transaction to use
                    // gasLimit: 310000,

                    // gasLimit: 93000,
                    gasLimit: 1400000,

                    value: costFinal3.toString()
                });

            }

            let tx = await res.wait();

            setLoading(false);
            setRoulling(true);

            let resultName = 'Lost';

            for(let i = 0; i < tx.events.length; i++){
                if(tx.events[i].event === 'Lost'){
                    resultName = 'Lost'
                }

                if(tx.events[i].event === 'Win'){
                    resultName = 'Win'
                }
            }

            if (resultName === 'Lost') {
                setStatusDone(false);
                setStatusError(true);
            } else {
                setStatusDone(true);
                setStatusError(false);
            }
        } catch (error) {
            setStatusErrorRPC(true);
            setRoulling(true);
            setLoading(false);

        }

    }

    return (
        <div className="team-box flex-mint">

            {statusWarn ?
                <img src={`https://media1.giphy.com/media/EahYBxGgJHLZ6/giphy.gif?cid=ecf05e47dobuttvllbqaxfgpyau200l420iy777vhitosjs3&rid=giphy.gif&ct=g`} alt="Ticket4" className="box-mint-warn" />
                : null}

            {statusDone ?
                <>
                    <div className="box-mint-done" style={{ fontSize: 16 }}>
                        <span>Você ganhou {level === 1 ? `1` : level === 2 ? `3` : `5`} MATIC + NFT</span>
                    </div>

                    <img src={`https://media2.giphy.com/media/3ohryhNgUwwZyxgktq/giphy.gif?cid=ecf05e47lhogi9pf3g4fyvfww1bt435cqpd9qh3u3g0yv2xx&rid=giphy.gif&ct=g`} alt="Ticket4" className="box-mint" />
                </>
                : null}

            {statusError ?
                <>
                    <div className="box-mint-warn" style={{ fontSize: 16 }}>
                        <span>Você tem agora uma magnifica NFT, jogue novamente!</span>
                    </div>
                </>
                : null}

            {/* {statusErrorRPC ?
                <>
                    <div className="box-mint-error">
                        <span>TRANSACTION ERROR :(</span>
                    </div>

                    <img src={`https://media3.giphy.com/media/pYI1hSqUdcBiw/giphy.gif?cid=ecf05e471y8q88kez4or58cq3intloysvw6yr3jxfjfvy4nk&rid=giphy.gif&ct=g`} alt="Ticket4" className="box-mint-warn" />

                </>
                : null} */}

            <>
                <div className="content">
                    <div className="mint-title">Jogue a bomba e tenha 50% de chance de ganhar 2x sua aposta + NFT</div>
                </div>

                {loading ?
                    <img src={loadingGif} alt="Ticket4" className="img-flip" />
                    : <img src={imgFlip} className="img-flip" alt="Ticket1" />
                }

                <div className="buttons-flex">
                    <div className={side_1 ? 'image-mint-select' : 'image-mint'} onClick={() => select1()}>
                        <img src={img1} alt="Ticket1" />
                    </div>

                    <div className={side_2 ? 'image-mint-select' : 'image-mint'} onClick={() => select2()}>
                        <img src={img2} alt="Ticket2" />
                    </div>
                </div>

                <div className="buttons-flex2">
                    <span className="tf-button-st2 btn-effect btn-effect-2 top-top" data-toggle="modal" data-target="#popup_bid" onClick={() => minter(1)}>
                        <span className="effect" style={{ fontSize: 16 }}>1 MATIC</span>
                    </span>

                    <span className="tf-button-st2 btn-effect btn-effect-2 top-top" data-toggle="modal" data-target="#popup_bid" onClick={() => minter(2)}>
                        <span className="effect" style={{ fontSize: 16 }}>3 MATIC</span>
                    </span>

                    <span className="tf-button-st2 btn-effect btn-effect-2 top-top" data-toggle="modal" data-target="#popup_bid" onClick={() => minter(3)}>
                        <span className="effect" style={{ fontSize: 16 }}>5 MATIC</span>
                    </span>
                </div>
            </>

            {/* {roulling ? <div className="flex-mint-2">
                <span className="tf-button-st2 btn-effect btn-effect-2" data-toggle="modal" data-target="#popup_bid" onClick={() => playAgain()}>
                    <span className="effect">Play again</span>
                </span>
            </div> : null} */}

            <div className="content">
                <div className="mint-title-ref" onClick={() => navigator.clipboard.writeText(`www.bet.cryptobomber.io/?ref=${account}`)}>⬇️ REFERRAL LINK ⬇️</div>

                <div className="mint-title-ref-link2" onClick={() => navigator.clipboard.writeText(`www.bet.cryptobomber.io/?ref=${account}`)}>click to copy 💾</div>


                <div className="mint-title-ref-link" onClick={() => navigator.clipboard.writeText(`www.bet.cryptobomber.io/?ref=${account}`)}>www.bet.cryptobomber.io/?ref={account}</div>

            </div>

        </div>
    );
}

export default Card;