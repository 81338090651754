import Home from "./Home";
import Page404 from "./404";
import NftItem from "./NftItem";

const routes = [
  { path: '/', component: <Home />},
  { path: '/404', component: <Page404 />},
  { path: '/nfts', component: <NftItem />},
]

export default routes;